/*
  BRAND LEVEL THEME
  If a project has multiple sites that have shared theming, those styles can be placed here.
  If a site needs to override a brand level style, that can be done by writing the desired override in the site level theme.js file.
*/
export default {
  colors: {
    primary: '#48D597',
    secondary: '#0047BB',
    tertiary: '#001E60',
    dark: '#1D2329',
    light: '#EEEEEE',
    warning: '#FF7928',
    error: '#FF3068',
    success: '#2DE6BC',
    background1: '#F0FBF4',
    background2: '#D0F3DF',
    background3: '#E5EDFA',
    background4: '#CCDBF5',
    background5: '#CBCBCB',
    background6: '#575F68',
  },
  gradients: {
    primary: 'linear-gradient(to right, #0047BB, #0047BB)',
    secondary: 'linear-gradient(to right, #0047BB, #0047BB)',
    tertiary: 'linear-gradient(to right, #0047BB, #0047BB)',
  },
  alternateBgColor: true,
  fonts: {
    headings: {
      family: '\'Arial\', \'sans-serif\'',
    },
    subheadings: {
      family: '\'Arial\', \'sans-serif\'',
    },
    body: {
      family: '\'Arial\', \'sans-serif\'',
      legal: {
        size: '12px',
        lineHeight: '14px',
      },
    },
  },
  components: {
    PackageCard: {
      color: '#1D2329',
    },
    Button: {
      rounded: 'min',
      variants: {
        primary: {
          color: 'black',
          hoverColor: 'black',
          activeColor: 'black',
        },
      },
    },
    ListItem: {
      icon: 'Done',
    },
    Price: {
      dollarSign: {
        size: '20px',
        position: 'top', // top, bottom (default), center
        weight: 'bold', // normal, bold
        offsetY: '5px',
        offsetX: '2px',
      },
      duration: {
        size: '20px',
        position: 'top', // top, bottom (default), center
        weight: 'normal', // normal, bold
        offsetY: '5px',
        offsetX: '-2px',
      },
    },
  },
  shadow: '0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1)',
  radius: '4px',
}
